import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useContext, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { APIContext } from "../connector";

const schema = Yup.object().shape({
  from_location: Yup.string().required("Pickup location is a required field"),
  to_location: Yup.string().required("Destination is a required field"),
  pax: Yup.number()
    .required("Pax is a required field")
    .integer("Input a valid number")
    .max(4, "Maximum of 4 passengers"),
});

const Booking = () => {
  const { sendJsonMessage, lastJsonMessage, userData } = useContext(APIContext);

  const userName = userData?.name || "User";

  const [buttonStatus, setButtonStatus] = useState("BOOK");
  const [isLoadingVisible, setIsLoadingVisible] = useState(false);
  const [tripID, setTripID] = useState(null);

  const buttonStatusRef = useRef(buttonStatus); // using ref to access the latest state value inside setTimeout closure
  buttonStatusRef.current = buttonStatus;

  const navigate = useNavigate();
  // const { state } = useLocation();

  useEffect(() => {
    console.log("Got a new message:", { lastJsonMessage });
    if (lastJsonMessage?.event === "trip_created") {
      setTripID(lastJsonMessage?.data?.trip_id);
      toast.success("Trip created successfully", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (lastJsonMessage?.type === "first_offer") {
      navigate("/driver-found", { state: { ...lastJsonMessage?.data } });
    }
  }, [lastJsonMessage]);

  const handleBooking = (data) => {
    console.log("Inside, button status:", buttonStatusRef.current);
    // Make sure the user hasn't cancelled in this 5s duration
    if (buttonStatusRef.current === "CANCEL") {
      setIsLoadingVisible(true);
      sendJsonMessage({
        event: "create_trip",
        data: JSON.stringify(data),
      });
    }
  };

  const handleButtonClick = (values) => {
    if (buttonStatus === "BOOK") {
      setButtonStatus("CANCEL");
      setTimeout(() => {
        handleBooking(values);
      }, 5000);
    } else if (buttonStatus === "CANCEL") {
      if (isLoadingVisible) {
        toast.info("Trip cancelled!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        sendJsonMessage({
          event: "cancel_trip",
          trip_id: tripID,
        });
        setIsLoadingVisible(false);
      }
      setButtonStatus("BOOK");
    }
  };

  return (
    <>
      {/* Wrapping form inside formik tag and passing our schema to validationSchema prop */}
      <Formik
        validationSchema={schema}
        initialValues={{
          name: userName,
          from_location: "",
          to_location: "",
          pax: "",
          prefer_private: false,
        }}
        onSubmit={handleButtonClick}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <div className="register">
            <div className="form">
              {/* Passing handleSubmit parameter tohtml form onSubmit property */}
              <form noValidate onSubmit={handleSubmit}>
                {!isLoadingVisible && (
                  <div className="userName">
                    <span>{userName}</span>
                  </div>
                )}
                {isLoadingVisible && (
                  <div className="spin-container">
                    <svg className="spinner" viewBox="0 0 50 50">
                      <circle
                        className="path"
                        cx="25"
                        cy="25"
                        r="20"
                        fill="none"
                        strokeWidth="5"
                      ></circle>
                    </svg>
                    <span className="wait">Looking for a senior....</span>
                  </div>
                )}
                {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                <p className="huh">From</p>
                <input
                  type="from_location"
                  name="from_location"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.from_location}
                  placeholder="KK12"
                  disabled={buttonStatus === "CANCEL"}
                  className="form-control inp_text"
                  id="from_location"
                />
                {/* If validation is not passed show errors */}
                <p className="error">
                  {errors.from_location &&
                    touched.from_location &&
                    errors.from_location}
                </p>
                <p className="huh">To</p>
                <input
                  type="to_location"
                  name="to_location"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.to_location}
                  placeholder="Faculty of Science"
                  disabled={buttonStatus === "CANCEL"}
                  className="form-control inp_text"
                  id="to_location"
                />
                {/* If validation is not passed show errors */}
                <p className="error">
                  {errors.to_location &&
                    touched.to_location &&
                    errors.to_location}
                </p>
                {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                <p className="huh">Pax</p>
                <select
                  type="pax"
                  name="pax"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.pax}
                  placeholder="Pax"
                  disabled={buttonStatus === "CANCEL"}
                  className="form-control inp_text"
                  id="pax"
                >
                  <option value="" disabled selected>
                    Choose no. of Pax
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
                <p className="error">
                  {errors.pax && touched.pax && errors.pax}
                </p>
                <div className="prefer-private">
                  <input
                    id="prefer_private"
                    name="prefer_private"
                    className="check-input"
                    type="checkbox"
                    disabled={buttonStatus === "CANCEL"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  Prefer private ride
                </div>
                {/* Click on submit button to submit the form */}
                <button
                  style={{
                    backgroundColor:
                      buttonStatus === "BOOK" ? "#008000" : "#FF0000",
                  }}
                  type="submit"
                >
                  {buttonStatus}
                </button>
              </form>
            </div>
          </div>
        )}
      </Formik>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default Booking;
