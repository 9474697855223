import React from "react";

const Waiting = () => {
  return (
    <>
      <div className="register">
        <div className="form">
          <form>
            <div className="waiting-spin">
              <div class="loader"></div>
              <span className="wait">Waiting for Approval</span>
            </div>
            <div className="padding"></div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Waiting;
