import TripCard from "./TripCard";
import "../CSS/CurrentOrders.scss";
import { React, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";

const CurrentOrders = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [trips, setTrips] = useState(state?.trips); //to store the current order(s) data
  const [completedCount, setCompletedCount] = useState(0);


  useEffect(() => {
    //// This will trigger a confirmation dialog when the user tries to leave the page
    window.onbeforeunload = (event) => {
      const e = event || window.event;
      // Cancel the event
      e.preventDefault();
      if (e) {
        e.returnValue = ""; // Legacy method for cross browser support
      }
      return ""; // Legacy method for cross browser support
    };
  }, []);
  const handleNextClick = () => {
    navigate("/driver");
  };

  return (
    <>
      <div className="basic">
        <div className="basic-body center">
          {state?.trips?.map((trip) => (
            // console.log("CurrentOrders map trip", trip);

            <TripCard
              key={trip.trip_details.trip_id}
              trip={trip}
              triggerToast={toast}
              setCompletedCount={setCompletedCount}
            />
          ))}
          <div className="next-button">
            <button
              onClick={() => {
                handleNextClick();
              }}
              className={
                completedCount === trips?.length ? "yes" : "yes-disabled"
              }
              disabled={!(completedCount === trips?.length)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default CurrentOrders;
