import { Link } from "react-router-dom";
import logo from "../static/images/Logo.png";

const Home = () => {
  return (
    <div className="centered-container">
      <img
        src={logo}
        alt=""
        className="centered-image"
        width="200"
        height="200"
      />
      <Link to="/login" className="link-style">
        <button className="centered-button">Get Started</button>
      </Link>
    </div>
  );
};

export default Home;
