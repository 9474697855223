import "../index.scss";
import "../App.scss";
import React from "react";
import { useNavigate } from "react-router-dom";
import { api_url } from "../constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const validateSelection = (selectedOrders) => {
  //select 1 order with 4 pax or 3 orders with a maximum of 3 pax
  if (selectedOrders.length === 1 && selectedOrders[0].pax <= 4) {
    return true;
  }
  if (selectedOrders.length > 1 && selectedOrders.length <= 3) {
    let totalPax = 0;
    selectedOrders.forEach((order) => {
      totalPax += order.pax;
    });
    if (totalPax <= 3) {
      return true;
    }
  }
  return false;
};

const DriverOrders = () => {
  const navigate = useNavigate();

  const headers = ["From", "To", "Pax", "Accept"];
  const [orders, setOrders] = React.useState([]);
  const [selectedOrders, setSelectedOrders] = React.useState([]);

  const reloadOrders = async () => {
    fetch(`${api_url}/api/trips`, {
      method: "GET",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          return response.text().then((text) => {
            throw new Error(`Error getting orders: ${text}`);
          });
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setOrders(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  React.useEffect(() => {
    // Getet the orders once then keep fetching and updating every 5 seconds
    reloadOrders();
    const intervalCall = setInterval(() => {
      reloadOrders();
    }, 5000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, []);

  const handleSelect = (event, row) => {
    if (event.target.checked) {
      setSelectedOrders([...selectedOrders, row]);
    } else {
      setSelectedOrders(selectedOrders.filter((order) => order.trip_id !== row.trip_id));
    }
  };

  const handleProceed = () => {
    if (validateSelection(selectedOrders)) {
      navigate("/send-order", { state: { orders: selectedOrders } });
    } else {
      toast.info("PLease select 1 order with 4 pax or 3 orders with a maximum of 3 pax", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <>
      <div className="background">
        <span className="orders">ORDERS</span>
        <div className="header">
          {headers.map((header) => {
            return (
              <span key={header} className="header-text">
                {header}
              </span>
            );
          })}
        </div>
        <div className="main">
          {[...orders]
            .sort((a, b) => {
              // sort orders by trip date
              let da = new Date(a.created_time),
                db = new Date(b.created_time);
              return db - da;
            })
            .map((row) => {
              return (
                <div key={row.trip_id} className="row">
                  <span className="row-cell">{row.from_location}</span>
                  <span className="row-cell">{row.to_location}</span>
                  <span className="row-cell">
                    {row.pax}
                    <span className="private_p">{row.prefer_private ? "private" : null}</span>
                  </span>
                  <input
                    className="radio-input"
                    type="checkbox"
                    onChange={(event) => handleSelect(event, row)}
                  />
                </div>
              );
            })}
        </div>
        <button onClick={handleProceed} className="button button-proceed">
          Proceed
        </button>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default DriverOrders;
