import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { APIContext } from "../connector";
import { api_url } from "../constants";
import { ToastContainer, toast } from "react-toastify";
import { googleLogout, useGoogleLogin, GoogleLogin } from "@react-oauth/google";
import "react-toastify/dist/ReactToastify.css";

const schema = Yup.object().shape({
  email: Yup.string().required("Email is a required field").email("Invalid email format"),
  password: Yup.string()
    .required("Password is a required field")
    .min(8, "Password must be at least 8 characters"),
});

const Login = () => {
  const navigate = useNavigate();
  const { reconnectSocket, setUserData } = useContext(APIContext);
  const [user, setUser] = useState();
  const [profile, setProfile] = useState();

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log("Login Success:", codeResponse);
      setUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (user) {
      fetch(`${api_url}/api/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((res) => {
              console.log(res);
              if (res.message === "User does not exist") {
                fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                  headers: {
                    Authorization: `Bearer ${user.access_token}`,
                    Accept: "application/json",
                  },
                })
                  .then((res) => res.json())
                  .then((values) => {
                    console.log(values);
                    navigate("/register", { state: { ...values, ...user } });
                  });
              }
              throw new Error(`Error logging in: ${res.message}`);
            }).catch((e) => {
              console.error(e);
              console.info(response);
            });
          }
          console.log(response);
          return response.json();
        })
        .then((data) => {
          localStorage.setItem("accessToken", data.token?.access);
          localStorage.setItem("refreshToken", data.token?.refresh);
          localStorage.setItem("google_auth_token", data?.google_auth_token);
          if (data.user_type === "CLIENT") {
            navigate("/booking", { state: { ...data } });
          }
          console.log("data returned:", data);
          fetch(`${api_url}/api/user`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${data?.token?.access}`,
            },
            credentials: "same-origin",
          })
            .then((response) => {
              if (!response.ok) {
                return response.json().then((res) => {
                  console.log(res);
                  throw new Error(`Error logging in: ${res.message}`);
                });
              }
              return response.json();
            })
            .then((user_data) => {
              reconnectSocket();
              setUserData(user_data);
              if (user_data?.user_type === "CLIENT") {
                navigate("/booking", { state: { ...user_data } });
              } else if (user_data?.user_type === "DRIVER") {
                if (user_data?.driver_info?.is_approved_driver === true) {
                  navigate("/driver", { state: { ...user_data } });
                } else if (user_data?.driver_info?.is_approved_driver === false) {
                  navigate("/waiting", { state: { ...user_data } });
                } else {
                  navigate("/driverRegister", { state: { ...user_data } });
                }
              }
            });
        })
        .catch((e) => {
          console.error(e);
          toast.error(`${e.message} `, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }
  }, [user]);

  const logOut = () => {
    googleLogout();
    setProfile(null);
  };

  // const responseMessage = (response) => {
  //   console.log("Success:", response);
  // };
  // const errorMessage = (error) => {
  //   console.log("Failure:", error);
  // };

  return (
    <>
      <div className="login">
        <div className="form">
          <span className="page-title">Login</span>
          <span className="about">Sign in to continue</span>
          <button onClick={login}>Sign in with Google </button>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};
export default Login;
