import React, { createContext, useState } from "react";
import useWebSocket from "react-use-websocket";
import { ws_url } from "./constants";

const APIContext = createContext();

const APIProvider = ({ children }) => {
  const [userData, setUserData] = useState({});
  const [token, setToken] = useState(localStorage.getItem("accessToken") || "");

  const WS_URL = `${ws_url}/ws/trip/`;
  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    WS_URL,
    {
      share: true,
      shouldReconnect: () => false,
      retryOnError: false,
      queryParams: {
        token,
      },
    }
  );

  const reconnectSocket = () => {
    const newToken = localStorage.getItem("accessToken") || "";
    if (newToken !== token && newToken !== "") {
      setToken(newToken);
    }
  };

  return (
    <APIContext.Provider
      value={{
        sendJsonMessage,
        lastJsonMessage,
        readyState,
        reconnectSocket,
        userData,
        setUserData,
      }}
    >
      {children}
    </APIContext.Provider>
  );
};

export { APIProvider, APIContext };
