import "../CSS/TripCard.scss";
import React, { useState, useContext } from "react";
import whatsapp from "../static/images/whatsapp.png";
import { useEffect } from "react";
import { APIContext } from "../connector";

const TripCard = (props) => {
  const { sendJsonMessage, lastJsonMessage } = useContext(APIContext);

  const [donede, setDonede] = useState(false);
  const [tripData, setTripData] = useState(props.trip);
  const [price, setPrice] = useState(props.trip?.trip_details?.price_offer);
  const [negoPrice, setNegoPrice] = useState(
    props.trip?.trip_details?.nego_offer
  );
  const [finalPrice, setFinalPrice] = useState(
    props.trip?.trip_details?.final_price
  );
  const [waiting, setWaiting] = useState(true);
  const [completed, setCompleted] = useState(false);
  const [cancelled, setCancelled] = useState(false);

  useEffect(() => {
    console.log("Got a new message:", { lastJsonMessage });
    const event = lastJsonMessage?.type;
    if (event === "trip_cancelled" && lastJsonMessage?.trip_id === tripData?.trip_details?.trip_id) {
      props.triggerToast.error("The user cancelled the ride", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      props.setCompletedCount((prev) => prev + 1);
      setCancelled(true);
    } else if (
      event === "nego_offer" &&
      lastJsonMessage?.data?.trip_details?.trip_id === tripData?.trip_details?.trip_id
    ) {
      props.triggerToast.info("The user made an offer", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setTripData(lastJsonMessage?.data);
    } else if (
      event === "final_offer" &&
      lastJsonMessage?.data?.trip_details?.trip_id === tripData?.trip_details?.trip_id
    ) {
      setTripData(lastJsonMessage?.data);
    } else if (
      event === "trip_accepted" &&
      lastJsonMessage?.data?.trip_details?.trip_id === tripData?.trip_details?.trip_id
    ) {
      props.triggerToast.success("Trip accepted", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setTripData(lastJsonMessage?.data);
      setDonede(true);
    }
  }, [lastJsonMessage]);

  const setFinalPrince = (price) => {
    sendJsonMessage({
      event: "make_final_offer",
      data: JSON.stringify({
        trip_id: tripData?.trip_details?.trip_id,
        price_offer: price,
      }),
    });
  };

  useEffect(() => {
    console.log(tripData);

    console.log("negoPrice: " + negoPrice);
    if (tripData.trip_details?.final_price) {
      setPrice(tripData.trip_details?.final_price);
      setWaiting(true);
    } else if (tripData.trip_details?.nego_offer) {
      setPrice(tripData.trip_details?.nego_offer);
      setNegoPrice(tripData.trip_details?.nego_offer);
      setWaiting(false);
      console.log("negoPrice set: " + negoPrice);
    }

    if (tripData.trip_details?.status === "IN_TRANSIT") {
      setDonede(true);
    }
  }, [finalPrice, negoPrice, tripData]);

  const wa_link = `https://wa.me/${tripData.user_details?.phone_number}`;

  const handleYesClick = () => {
    sendJsonMessage({
      event: "accept_offer",
      trip_id: tripData?.trip_details?.trip_id,
    });
  };

  const handlePriceClick = (val) => {
    setPrice(val);
    setWaiting(true);
    setFinalPrince(val);
  };

  const handleDoneClick = () => {
    setCompleted(true);
    props.setCompletedCount((prev) => prev + 1);
    sendJsonMessage({
      event: "complete_trip",
      trip_id: tripData?.trip_details?.trip_id,
    });
  };

  return (
    <>
      <div className="card">
        <span>{tripData.user_details?.name}</span>
        <p className="huh">From</p>
        <input
          value={tripData.trip_details?.from_location}
          className="form-control inp_text"
          id="pickup"
          disabled
        />
        <p className="huh">To</p>
        <input
          value={tripData.trip_details?.to_location}
          className="form-control inp_text"
          id="destination"
          disabled
        />
        <p className="huh">Pax</p>
        <input
          value={tripData.trip_details?.pax}
          className="form-control inp_text"
          id="pax"
          disabled
        />

        {!completed && (
          <>
            <p className="price">RM {price}</p>
            <div className="button-array">
              {donede && (
                <>
                  <div className="trip-intransit">
                    <div className="call-options">
                      <a href={wa_link} target="_blank" rel="noreferrer">
                        <img
                          src={whatsapp}
                          alt=""
                          // className="centered-image"
                          width="75"
                          height="75"
                        />
                      </a>
                    </div>
                    <button className="yes" onClick={() => handleDoneClick()}>
                      Done
                    </button>
                  </div>
                </>
              )}
              {!donede && !waiting && (
                <>
                  <button className="yes" onClick={() => handleYesClick()}>
                    Yes
                  </button>
                  <div className="nego">
                    <button
                      onClick={() =>
                        handlePriceClick(tripData.trip_details?.price_offer)
                      }
                    >
                      RM {tripData.trip_details?.price_offer}
                    </button>
                    {tripData.trip_details?.price_offer - negoPrice === 2 && (
                      <button
                        onClick={() =>
                          handlePriceClick(
                            tripData.trip_details?.price_offer - 1
                          )
                        }
                      >
                        RM {tripData.trip_details?.price_offer - 1}
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {waiting && !donede && !cancelled && (
          <span>Waiting for junior's response on offer of RM {price}</span>
        )}
        {!donede && !waiting && !cancelled && (
          <span>Junior negotiation price: RM {price}</span>
        )}
        {completed && !cancelled && (
          <>
            <p className="price">RM {price}</p>
            <span> Order Completed</span>
          </>
        )}
        {cancelled && <span>Junior cancelled the trip</span>}
      </div>
    </>
  );
};

export default TripCard;
