import { Link } from "react-router-dom";

const DriverList = () => {
  const headers = ["Name", "Gender", "Status", ""];
  const data = [
    {
      id: 1,
      name: "Adam",
      gender: "L",
      status: "Student",
    },
    {
      id: 2,
      name: "Fatma",
      gender: "P",
      status: "Student",
    },
    {
      id: 1,
      name: "Fahmi",
      gender: "L",
      status: "Other",
    },
    {
      id: 1,
      name: "Saleh",
      gender: "L",
      status: "Student",
    },
    {
      id: 1,
      name: "Mohammed",
      gender: "L",
      status: "Other",
    },
    {
      id: 1,
      name: "Fateen",
      gender: "L",
      status: "Other",
    },
    {
      id: 1,
      name: "Faizah",
      gender: "P",
      status: "Student",
    },
    {
      id: 1,
      name: "Amaan",
      gender: "L",
      status: "Other",
    },
    {
      id: 1,
      name: "Nurul",
      gender: "P",
      status: "Student",
    },
  ];

  return (
    <div className="background">
      <span className="orders">DRIVER LIST</span>
      <div className="header">
        {headers.map((header) => (
          <span className="header-text">{header}</span>
        ))}
      </div>
      <div className="main">
        {data.map((row) => {
          return (
            <div className="row">
              <span className="row-cell">{row.name}</span>
              <span className="row-cell">{row.gender}</span>
              <span className="row-cell">{row.status}</span>
              {/* <span className="row-cell">• • •</span> */}
              <button className="yes">details</button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DriverList;
