import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import DriverRegister from "./pages/DriverRegister";
import Booking from "./pages/Booking";
import DriverCheckIn from "./pages/DriverCheckIn";
import DriverOrders from "./pages/DriverOrders";
import DriverSendOrder from "./pages/DriverSendOrder";
import DriverFound from "./pages/DriverFound";
import Waiting from "./pages/Waiting";
import DriverOTW from "./pages/DriverOTW";
import CurrentOrders from "./pages/CurrentOrders";
import DriverList from "./pages/DriverList";
import Layout from "./layout";
import { APIProvider } from "./connector";

function App() {
  return (
    <APIProvider>
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route index path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/driverRegister" element={<DriverRegister />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/driver" element={<DriverCheckIn />} />
            <Route path="/driver-orders" element={<DriverOrders />} />
            <Route path="/send-order" element={<DriverSendOrder />} />
            <Route path="/driver-found" element={<DriverFound />} />
            <Route path="/waiting" element={<Waiting />} />
            <Route path="/driverOTW" element={<DriverOTW />} />
            <Route path="/currentOrders" element={<CurrentOrders />} />
            <Route path="/driverList" element={<DriverList />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </APIProvider>
  );
}

export default App;
