import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { APIContext } from "../connector";

const DriverSendOrder = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const headers = ["From", "To", "Pax", "Price"];
  const data = state?.orders || [];

  const initialPriceValues = data.reduce((acc, row) => ({ ...acc, [row.trip_id]: 5 }), {});
  console.log(initialPriceValues);

  const [priceValues, setPriceValues] = useState(initialPriceValues);
  const [pendingOfferConfirmation, setPendingOfferConfirmation] = useState(0);
  const [trips, setTrips] = useState([]); //to store the current order(s) data
  const { sendJsonMessage, lastJsonMessage } = useContext(APIContext);

  useEffect(() => {
    console.log("Got a new message:", { lastJsonMessage });
    console.log("Trips", trips);
    if (lastJsonMessage?.event === "error") {
      toast.info(lastJsonMessage.data, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setPendingOfferConfirmation((prev) => prev - 1);
    }
    if (
      lastJsonMessage?.type === "first_offer" &&
      !trips.find((trip) => trip.trip_details.trip_id === lastJsonMessage?.data.trip_details.trip_id)
    ) {
      console.log("Received new trip offer", lastJsonMessage?.data);
      console.log(pendingOfferConfirmation);
      setPendingOfferConfirmation((prev) => prev - 1);
      setTrips((currTrips) => [...currTrips, lastJsonMessage?.data]);
    } else if (trips.length > 0 && pendingOfferConfirmation === 0) {
      navigate("/currentOrders", { state: { trips: trips } });
    }
  }, [lastJsonMessage, trips, setPendingOfferConfirmation]);

  const handleChange = (e, id) => {
    setPriceValues({ ...priceValues, [id]: e.target.value });
  };

  const handleSendPrice = (e) => {
    e.preventDefault();
    for (const [key, value] of Object.entries(priceValues)) {
      if (value) {
        console.log("Sending offer for trip", key, "with price", value);
        setPendingOfferConfirmation((prev) => prev + 1);
        sendJsonMessage({
          event: "make_offer",
          data: JSON.stringify({
            trip_id: key,
            price_offer: value,
          }),
        });
      }
    }
  };

  const amounts = [5, 6, 7, 8, 9, 10, 11, 12];

  return (
    <>
      <div className="background">
        <span className="orders">ORDERS</span>
        <div className="header">
          {headers.map((header) => (
            <span className="header-text">{header}</span>
          ))}
        </div>
        <div className="main">
          {data.map((row) => {
            return (
              <div key={row.trip_id} className="row">
                <span className="row-cell">{row.from_location}</span>
                <span className="row-cell">{row.to_location}</span>
                <span className="row-cell">{row.pax}</span>
                <select
                  className="dropdown"
                  value={priceValues[`${row.trip_id}`]}
                  onChange={(e) => handleChange(e, row.trip_id)}
                >
                  {amounts.map((price) => (
                    <option value={price}>{price}</option>
                  ))}
                </select>
              </div>
            );
          })}
        </div>
        <button onClick={handleSendPrice} className="button button-proceed">
          Send
        </button>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default DriverSendOrder;
