import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useContext } from "react";
import { APIContext } from "../connector";

const DriverCheckIn = () => {
  const { userData } = useContext(APIContext);
  const location = useLocation();
  const navigate = useNavigate();
  // const { state } = useLocation();
  var data = {
    name: userData?.name || "Unknown",
    rides: userData?.driver_info?.number_of_trips || 0,
    credit: userData?.driver_info?.credit_balance || 0,
  };

  //I'm using useState so that in case the backend doesn't make it in time,
  //I'll use custom buttons to update the values of credit and show how it works
  // const [credit, setCredit] = useState(data.credit);
  // const [rides, setRides] = useState(data.rides);
  // const [error, setError] = useState(false);

  console.log(userData);

  return (
    <div className="driver">
      <div className="form">
        <div className="userName">
          <span>{data.name}</span>
        </div>
        <p className="huh">Total Rides</p>
        <input
          type="total_rides"
          name="total_rides"
          value={data.rides}
          placeholder="0"
          className="form-control inp_text"
          id="total_rides"
          disabled
        />
        <p className="huh">Rides Balance</p>
        <input
          type="balance"
          name="balance"
          value={data.credit}
          placeholder="Faculty of Science"
          className="form-control inp_text"
          id="balanace"
          disabled
        />
        {/* {error && (
          <p className="error">you need at least 1 ride balance to check-in</p>
        )} */}
        <button onClick={() => navigate("/driver-orders")}>Check-in</button>
      </div>
    </div>
  );
};

export default DriverCheckIn;
